import React, { useState } from "react";
import axios from "axios";
import {
    Flex,
    Heading,
    Input,
    Button,
    InputGroup,
    Stack,
    InputLeftElement,
    chakra,
    Box,
    Link,
    Image,
    Text,
    FormControl,
    InputRightElement,
    Spinner,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
// import APLogo from "/company.png";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = ({ checkAuthStatus }) => {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [isLoading, setIsLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleShowClick = () => setShowPassword(!showPassword);
    axios.defaults.withCredentials = true;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(
                `${
                    process.env.REACT_APP_STAGE == "production"
                        ? process.env.REACT_APP_SERVER_URL_PROD
                        : process.env.REACT_APP_SERVER_URL_DEV
                }/auth/login`,
                formData,
                { withCredentials: true }
            );
            console.log(response.data);
            // Check authentication status after successful login
            checkAuthStatus();
            // Redirect or perform actions after successful login
        } catch (error) {
            if (error.response) {
                alert(
                    "The username or password was incorrect, please try again."
                );
                // console.error(error.response.data.message);
            }

            // Handle login error
        }
        setIsLoading(false);
    };

    return (
        <>
            <Flex
                position={"absolute"}
                w={{ base: "20%", md: "10%", lg: "8%" }}
                left={3}
                top={3}
            >
                <a href="https://aviaproconsulting.ca/">
                    <Image src="/company.png" objectFit="cover" />
                </a>
            </Flex>
            <Flex
                className="loginPage"
                flexDirection="column"
                width="100wh"
                height="100vh"
                justifyContent="center"
                alignItems="center"
            >
                <Stack
                    flexDir="column"
                    mb="2"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box minW={{ base: "90%", md: "468px" }}>
                        <form onSubmit={handleSubmit}>
                            <Stack
                                spacing={4}
                                p="2.5rem"
                                backgroundColor="whiteAlpha.900"
                                boxShadow="lg"
                                borderRadius={"22px"}
                                borderWidth={"1px"}
                                borderColor={"blackAlpha.200"}
                            >
                                <Stack
                                    flexDir="column"
                                    mb="2"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Heading color="red.600">
                                        AviaPro Consulting Applications Platform
                                    </Heading>
                                    <Text fontWeight={"semibold"}>
                                        Version 0.03 (Release date: 1/31/2024)
                                    </Text>
                                </Stack>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <CFaUserAlt color="gray.300" />
                                            }
                                        />
                                        <Input
                                            type="text"
                                            id={"username"}
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            placeholder="Username"
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            color="gray.300"
                                            children={
                                                <CFaLock color="gray.300" />
                                            }
                                        />
                                        <Input
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Password"
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button
                                                h="1.75rem"
                                                size="sm"
                                                onClick={handleShowClick}
                                            >
                                                {showPassword ? "Hide" : "Show"}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>

                                <Button
                                    borderRadius={"md"}
                                    type="submit"
                                    variant="solid"
                                    colorScheme="red"
                                    width="full"
                                >
                                    {isLoading ? <Spinner /> : "Login"}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
                <Flex mt={2} gap={1} flexDirection={"horizontal"}>
                    <Text fontWeight="semibold">No Account? </Text>
                    <Link color="teal.500" href="signup">
                        Sign Up
                    </Link>
                </Flex>
            </Flex>
        </>
    );
};

export default Login;
