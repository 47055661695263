import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ auth, component }) => {
    const navigate = useNavigate();
    useEffect(() => {
        console.log(auth, "protected route");
        if (auth === false) {
            navigate("/login");
        }
    }, [auth, navigate]);

    return auth && <div>{component}</div>;
};

export default ProtectedRoute;
