import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ChakraProvider, Container, extendTheme } from "@chakra-ui/react";
import axios from "axios";
import Home from "./components/Home";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ProtectedLogin from "./components/ProtectedLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";

function App() {
    //auth check. a little different than the original from db
    // starts at indeterminate state instead of false
    const [isAuthenticated, setIsAuthenticated] = useState(undefined);
    // const navigate = useNavigate();

    //chakra theme
    const theme = extendTheme({
        fonts: {
            heading: `'Barlow', sans-serif`,
            body: `'Barlow', sans-serif`,
        },
    });

    useEffect(() => {
        // Check authentication status when the app starts
        checkAuthStatus();
    }, []);

    useEffect(() => {
        console.log(isAuthenticated, "???");
    }, [isAuthenticated]);

    const checkAuthStatus = async () => {
        try {
            const response = await axios.get(
                `${
                    process.env.REACT_APP_STAGE == "production"
                        ? process.env.REACT_APP_SERVER_URL_PROD
                        : process.env.REACT_APP_SERVER_URL_DEV
                }/auth/check-auth`,
                { withCredentials: true }
            );
            setIsAuthenticated(response.data.isAuthenticated);
        } catch (error) {
            // if error then it's not authenticated
            setIsAuthenticated(false);
        }
    };

    return (
        <Container>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedLogin
                                    auth={isAuthenticated}
                                    component={
                                        <Login
                                            checkAuthStatus={checkAuthStatus}
                                        />
                                    }
                                />
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <ProtectedLogin
                                    auth={isAuthenticated}
                                    component={
                                        <Login
                                            checkAuthStatus={checkAuthStatus}
                                        />
                                    }
                                />
                            }
                        />
                        <Route path="/signup" element={<SignUp />} />
                        <Route
                            path="/Home"
                            element={
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    component={
                                        <Home logout={setIsAuthenticated} />
                                    }
                                />
                            }
                        />
                        <Route
                            path="/*"
                            element={
                                <ProtectedRoute
                                    auth={isAuthenticated}
                                    component={
                                        <Home logout={setIsAuthenticated} />
                                    }
                                />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </Container>
    );
}

export default App;
