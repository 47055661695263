import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Flex,
    Heading,
    Input,
    Button,
    InputGroup,
    Stack,
    InputLeftElement,
    chakra,
    Box,
    Link,
    FormControl,
    Image,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const MDEmail = chakra(MdEmail);

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowClick = () => setShowPassword(!showPassword);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        email: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const checkEmail = () => {
        let tempEmail = formData.email;
        let atSlice = tempEmail.indexOf("@");
        let domainName = tempEmail.slice(atSlice + 1, tempEmail.length);
        if (domainName == "aviaproconsulting.com") {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailCheck = checkEmail();
        if (emailCheck) {
            try {
                fetch(
                    `${
                        process.env.REACT_APP_STAGE == "production"
                            ? process.env.REACT_APP_SERVER_URL_PROD
                            : process.env.REACT_APP_SERVER_URL_DEV
                    }/auth/signup`,
                    {
                        method: "POST",
                        mode: "cors",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(formData),
                    }
                )
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.error) {
                            alert(json.error);
                        } else {
                            // Redirect or perform actions after successful sign-up
                            navigate("/");
                        }
                    });
            } catch (error) {
                // Handle sign-up error
                console.error(error);
            }
        } else {
            alert("You are not a part of this organization!");
        }
    };

    return (
        <>
            <Flex
                position={"absolute"}
                w={{ base: "20%", md: "10%", lg: "8%" }}
                left={3}
                top={3}
            >
                <a href="https://aviaproconsulting.ca/">
                    <Image src="/company.png" objectFit="cover" />
                </a>
            </Flex>
            <Flex
                className="loginPage"
                flexDirection="column"
                width="100wh"
                height="100vh"
                justifyContent="center"
                alignItems="center"
            >
                <Stack
                    flexDir="column"
                    mb="2"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box minW={{ base: "90%", md: "468px" }}>
                        <form onSubmit={handleSubmit}>
                            <Stack
                                spacing={4}
                                p="2.5rem"
                                backgroundColor="whiteAlpha.900"
                                boxShadow="lg"
                                border={"1px"}
                                borderColor={"blackAlpha.200"}
                                borderRadius={"22px"}
                            >
                                <Stack
                                    flexDir="column"
                                    mb="2"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Heading color="red.600">
                                        AviaPro Consulting Applications Signup
                                    </Heading>
                                </Stack>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <MDEmail color="gray.300" />
                                            }
                                        />
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="email address"
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <CFaUserAlt color="gray.300" />
                                            }
                                        />
                                        <Input
                                            type="text"
                                            id="username"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            placeholder="Username"
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            color="gray.300"
                                            children={
                                                <CFaLock color="gray.300" />
                                            }
                                        />
                                        <Input
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="Password"
                                            // onChange={(e) => {
                                            // 	setPassword(e.target.value);
                                            // }}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button
                                                h="1.75rem"
                                                size="sm"
                                                onClick={handleShowClick}
                                            >
                                                {showPassword ? "Hide" : "Show"}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    type="submit"
                                    // onClick={register}
                                    variant="solid"
                                    borderRadius={"md"}
                                    colorScheme="red"
                                    // background={"#A50A0"}
                                    // bgColor={"#A50A0"}
                                    // color={"#A50A0"}
                                    width="full"
                                >
                                    Signup
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
                <Box display={"flex"} gap={2} flexDir={"row"}>
                    <Text fontWeight={"bold"}>Already have an account? </Text>
                    <Link color="teal.500" href="/">
                        Log In
                    </Link>
                </Box>
            </Flex>
        </>
    );
};

export default SignUp;
