import React from "react";
import {
    Button,
    Heading,
    Link as ChakraLink,
    Card,
    CardBody,
    CardFooter,
    Image,
    Text,
    Flex,
    Stack,
    Spacer,
} from "@chakra-ui/react";

const ProjectCard = ({
    imageURL,
    title,
    description,
    baseURL,
    projectSubDomain,
    buttonTitle,
    isDisabled,
    documentation,
    documentationLink,
    documentationDisabled,
}) => {
    return (
        <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            key={title}
            boxShadow={"lg"}
            borderRadius={"22px"}
            p={4}
            borderWidth={"1px"}
        >
            <Flex flexDir={{ base: "column", sm: "row" }} w={"full"} gap={6}>
                <Flex justifyItems={"center"} alignItems={"center"}>
                    <Image
                        justifyContent={"center"}
                        objectFit="cover"
                        maxW={"100%"}
                        maxH={"8em"}
                        sx={{ filter: "grayscale(100%)" }}
                        src={`${imageURL}`}
                        alt={`${imageURL}`}
                        style={{
                            position: "relative",
                            marginLeft: "10px",
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                    />
                </Flex>
                <Flex flexDir={"column"} h={"full"} w={"full"}>
                    <CardBody p={2}>
                        <Heading
                            size="md"
                            fontWeight={"bold"}
                            fontSize={{
                                base: "14px",
                                sm: "15px",
                                md: "18px",
                            }}
                        >
                            {title}
                        </Heading>
                        <Text
                            fontSize={{ base: "12px", sm: "13px", md: "14px" }}
                        >
                            {description}
                        </Text>
                    </CardBody>

                    <CardFooter
                        p={2}
                        gap={4}
                        justifyContent={{ base: "center", sm: "start" }}
                        flexDir={{ base: "column", sm: "row" }}
                    >
                        <Flex
                            h={"full"}
                            w={{ base: "full", sm: "auto" }}
                            alignItems={"end"}
                        >
                            <ChakraLink
                                as="a"
                                href={`${baseURL}/${projectSubDomain}`}
                                rel="noopener noreferrer"
                                w={"full"}
                            >
                                <Button
                                    w={"full"}
                                    variant="solid"
                                    colorScheme="blue"
                                    isDisabled={isDisabled}
                                    size={{ base: "sm", sm: "sm", md: "md" }}
                                >
                                    <Text
                                        w={"full"}
                                        fontSize={{
                                            base: "12px",
                                            sm: "13px",
                                            md: "14px",
                                        }}
                                    >
                                        {buttonTitle}
                                    </Text>
                                </Button>
                            </ChakraLink>
                        </Flex>
                        <Flex h={"full"} w={"full"} alignItems={"end"}>
                            <ChakraLink
                                as="a"
                                w={"full"}
                                href={`${documentationLink}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Button
                                    w={{ base: "full", sm: "auto" }}
                                    variant="solid"
                                    colorScheme="green"
                                    size={{ base: "sm", sm: "sm", md: "md" }}
                                    isDisabled={documentationDisabled}
                                >
                                    <Text
                                        fontSize={{
                                            base: "12px",
                                            sm: "13px",
                                            md: "14px",
                                        }}
                                    >
                                        {documentation}
                                    </Text>
                                </Button>
                            </ChakraLink>
                        </Flex>
                    </CardFooter>
                </Flex>
            </Flex>
        </Card>
    );
};

export default ProjectCard;
